"use client"
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import { BANNER_DOWNLOAD_BUTTON_TYPE, HEADER_DOWNLOAD_BUTTON_TYPE } from "../../../lib/utils/constants/GlobalConstants";
import React, {useRef} from "react";
import ButtonClickAnimation from "../../animation/ButtonClickAnimation";
import useIsMobile from "../../../lib/customHooks/isMobile";
import styles from "./GDCSubmitButton.module.css"

const GDCSubmitButton = (props) => {
    const { type, text, disabled=false, sendAnalytics, isSecondary= false } = props;
    const {isMobileDevice} = useIsMobile();
    let anchorRef: any = useRef(null);
    const handleClick = () => {
        anchorRef.current.click();
        sendAnalytics();
    };
    return (
        <div>

            <>
                <ButtonClickAnimation>
                    <span onClick={(event) => handleClick()}>
                        <span className={styles.buttonContainer} style={{
                            marginTop: type === HEADER_DOWNLOAD_BUTTON_TYPE
                                ? "0px"
                                : "15px",
                            cursor: disabled ? "auto" : "pointer",
                            width: isMobileDevice && type === BANNER_DOWNLOAD_BUTTON_TYPE
                                ? "70vw"
                                : "100%"
                        }}>
                            <div className={styles.mainContainer} style={{
                                background: isSecondary ? "none": disabled
                                    ? "linear-gradient(to bottom,#eee,#ccc)"
                                    : "linear-gradient(to bottom,#b1fd2b,#31eb6f)",
                                border:isSecondary?"1px solid #ccc":"none"
                            }}>
                                <div className={styles.content} style={{
                                    backgroundImage: isSecondary ? "none": disabled
                                        ? "linear-gradient(to bottom,#eee,#ccc),linear-gradient(to bottom, #eee, #ccc)"
                                        : "linear-gradient(to bottom,#57cc03,#004f1c),linear-gradient(to bottom, #b1fd2b, #31eb6f)",
                                    fontSize: type === BANNER_DOWNLOAD_BUTTON_TYPE
                                        ? dynamicCssSize(36, 18)
                                        : type === HEADER_DOWNLOAD_BUTTON_TYPE
                                        ? dynamicCssSize(18, 9)
                                        : dynamicCssSize(24, 12),
                                    padding: type === BANNER_DOWNLOAD_BUTTON_TYPE
                                        ? "3px"
                                        : "6px",
                                    minWidth: type === BANNER_DOWNLOAD_BUTTON_TYPE
                                        ? dynamicCssSize(450, 100)
                                        : type === HEADER_DOWNLOAD_BUTTON_TYPE
                                        ? dynamicCssSize(150, 50)
                                        : dynamicCssSize(250, 80)
                                }}>
                                    <div className={styles.shimmer}></div>

                                    <p>{text}</p>
                                </div>
                            </div>
                        </span>
                    </span>
                </ButtonClickAnimation>
                <div style={{ display: "none" }}>
                    <a
                        href={props.url}
                        ref={anchorRef}
                        suppressHydrationWarning
                        target="_blank"
                    ></a>
                </div>
            </>
        </div>
    );
};

export default GDCSubmitButton;
