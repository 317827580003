"use client";

import {
  checkIsGetAppFb,
  debounce,
  deviceArchitecture,
  deviceModelNumber,
  dynamicCssSize,
  getCookie,
  getDeviceArchitecture,
  getOnlineStatus,
  getQueryStrings,
  isHowToVideoAvailable,
  isiOSDevice,
  onScrollStop,
  updateAppUrls,
} from "../../../lib/utils/constants/PagesConstants";
import { IFixedDownloadButtonProps } from "../../../lib/types/components";
import {
  APK_DOWNLOAD_AGAIN_DELAY,
  BANNER_DOWNLOAD_BUTTON_TYPE,
  DEVICE_MODEL,
  FIXED_DOWNLOAD_BUTTON_TYPE,
  GET_APP_FACEBOOK_URL,
  GET_APP_PLAY_URL,
  GET_APP_URL,
  HEADER_DOWNLOAD_BUTTON_TYPE,
  INITIAL,
  IOS,
  NOT_FOUND,
  UTM_AUTO_DOWNLOAD,
  UTM_HOTSTAR,
  UTM_PLAYSTORE,
  VariantB,
} from "../../../lib/utils/constants/GlobalConstants";
import React, { useEffect, useRef, useState } from "react";
import Analytics from "../../../lib/analytics/Analytics";
import {
  AutoDownloadEvent,
  iosCtaColorClickEvent,
  iosCtaColorEvent,
} from "../../../lib/analytics/AnalyticsEvents";
import useIsIOS from "../../../lib/utils/helpers/useIsIOS";
import ButtonClickAnimation from "../../animation/ButtonClickAnimation";
import {
  DownloadButtonState,
  useDownloadContext,
} from "../../../lib/context/CommonContext";
import useIsMobile from "../../../lib/customHooks/isMobile";
import {
  buttonClickType,
  multipleClassName,
  setButtonClickType,
} from "../../../lib/utils/helpers/helper";
import styles from "./FixedDownloadButton.module.css";
import parse from "html-react-parser";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";

const FixedDownloadButton = (props: IFixedDownloadButtonProps) => {
  const {
    pageName,
    fixedDownloadButtonData,
    type,
    iosCtaData,
    storyFlow = false,
    showAnimation = false,
  } = props;

  let anchorRef = useRef<HTMLAnchorElement>(null);

  const isGetApp: boolean = pageName === GET_APP_URL;
  const isGetAppFacebook: boolean = pageName === GET_APP_FACEBOOK_URL;
  const isGetAppPlay: boolean = pageName === GET_APP_PLAY_URL;
  const isGetAppFb: boolean = checkIsGetAppFb(pageName);
  const isUtmHotstar: boolean = getQueryStrings().utm === UTM_HOTSTAR;
  const isUtmPlayStore: boolean = getQueryStrings().utm === UTM_PLAYSTORE;

  const {
    locale,
    downloadButtonState,
    setDownloadButtonState,
    countdown,
    startCountdown,
    deviceArchitectureMapping,
    setClickApkUrl,
    isAutoDownloadFlow,
    setIsAutoDownloadFlow,
    setClickedBtnType,
    appVersions,
    downloadUrl: apkUrl,
  } = useDownloadContext();

  const { isMobileDevice } = useIsMobile();
  let isIos = useIsIOS();
  const [downloadButtonText, setDownloadButtonText] = useState(
    fixedDownloadButtonData.text2
  );
  const [downloadButtonAboveText, setDownloadButtonAboveText] =
    useState<string>("");

  useEffect(() => {
    if (
      downloadButtonState === DownloadButtonState.Initial &&
      isMobileDevice &&
      getQueryStrings().utm === UTM_AUTO_DOWNLOAD
    ) {
      setIsAutoDownloadFlow(true);
      if (type === HEADER_DOWNLOAD_BUTTON_TYPE) {
        Analytics.getInstance().logEvent(AutoDownloadEvent());
      }
      if (
        isHowToVideoAvailable &&
        getQueryStrings().utm !== UTM_AUTO_DOWNLOAD
      ) {
        triggerDownloadWithDelay();
      } else {
        handleDownloadClick();
      }
    } else {
      if (
        downloadButtonState !== DownloadButtonState.DownloadClicked &&
        getQueryStrings().utm === UTM_AUTO_DOWNLOAD
      ) {
        setDownloadButtonText(getDownloadButtonText());
      } else if (downloadButtonState !== DownloadButtonState.DownloadClicked) {
        setDownloadButtonText(getDownloadButtonText());
      }
    }

    if (isUtmPlayStore) {
      setDownloadButtonAboveText(
        fixedDownloadButtonData.downloadButtonAboveTextPlaystore ||
          "You are an existing user of the full version of WinZO. Upgrade your current app here.<br />Downloading outside Google Playstore App."
      );
    } else if (isUtmHotstar) {
      setDownloadButtonAboveText(
        fixedDownloadButtonData.downloadButtonAboveTextHotstar ||
          "Outside Google Playstore App"
      );
    } else if (downloadButtonAboveText.length) {
      setDownloadButtonAboveText("");
    }
  }, [downloadButtonState, isMobileDevice, getQueryStrings().utm, locale]);

  useEffect(() => {
    if (
      countdown !== null &&
      countdown >= 0 &&
      downloadButtonState === DownloadButtonState.DownloadClicked
    ) {
      setDownloadButtonText(countdown.toString());

      if (countdown === 0) {
        if (type === buttonClickType) {
          handleDownloadClick();
        }
      }
    }
  }, [countdown]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isHowToVideoAvailable) {
      if (
        downloadButtonState === DownloadButtonState.DownloadClicked ||
        downloadButtonState === DownloadButtonState.Downloading
      ) {
        timer = setTimeout(() => {
          // setIsApkDownloading(false);
          setDownloadButtonState(DownloadButtonState.DownloadAgain);
        }, APK_DOWNLOAD_AGAIN_DELAY);
      }
    }

    return () => clearTimeout(timer);
  }, [downloadButtonState]);

  const triggerDownloadWithDelay = () => {
    const countdown = props.fixedDownloadButtonData.countdown
      ? props.fixedDownloadButtonData.countdown
      : 5;
    setButtonClickType(type);
    setDownloadButtonState(DownloadButtonState.DownloadClicked);
    startCountdown(countdown);
  };

  const getDownloadButtonText = () => {
    let text: string;

    if (downloadButtonState === DownloadButtonState.DownloadAgain) {
      text = fixedDownloadButtonData.text4 || "Download Again";
    } else if (downloadButtonState === DownloadButtonState.Downloading) {
      text = fixedDownloadButtonData.text1;
    } else if (isUtmPlayStore && isGetAppPlay) {
      text = fixedDownloadButtonData.text5 || "Download APP";
    } else if (isUtmHotstar && isGetApp) {
      text = fixedDownloadButtonData.text6 || "Download & Get ₹550";
    } else if (type === HEADER_DOWNLOAD_BUTTON_TYPE) {
      text = fixedDownloadButtonData.text3;
    } else {
      text = fixedDownloadButtonData.text2;
    }

    return text;
  };

  const handleDownloadClickWithState = () => {
    if (fixedDownloadButtonData.disabled) {
      return;
    }
    setClickedBtnType(type);
    setIsAutoDownloadFlow(false);
    if (
      downloadButtonState === DownloadButtonState.Initial &&
      isHowToVideoAvailable
    ) {
      triggerDownloadWithDelay();
    } else {
      handleDownloadClick();
    }
  };

  const handleDownloadClick = (): void => {
    if (apkUrl.includes("apk")) {
      setDownloadButtonState(DownloadButtonState.Downloading);
      // setIsApkDownloading(true);
    }

    if (fixedDownloadButtonData.disabled) {
      return;
    }

    if (!isiOSDevice) {
      let anchor = anchorRef.current!;
      let url = apkUrl;
      anchor.href = url;
      let archInUrl = getQueryStrings(apkUrl).arch;
      if (
        archInUrl &&
        deviceArchitectureMapping &&
        (archInUrl === "vu" || archInUrl === INITIAL || archInUrl === NOT_FOUND)
      ) {
        if (
          getCookie(DEVICE_MODEL) === NOT_FOUND &&
          // @ts-ignore
          typeof navigator.userAgentData !== "undefined"
        ) {
          // @ts-ignore
          navigator.userAgentData
            .getHighEntropyValues(["model"])
            .then((values) => {
              let deviceArchitecture = getDeviceArchitecture(
                deviceArchitectureMapping,
                values.model || NOT_FOUND
              );
              url = updateAppUrls(
                deviceArchitecture,
                locale!,
                appVersions!,
                pageName!
              );
              setClickApkUrl(url);
              anchor.href = url;
              debounce(() => anchor.click(), 120)();
            });
        } else {
          let deviceModel: string | undefined = getCookie(DEVICE_MODEL);
          let deviceArchitecture = getDeviceArchitecture(
            deviceArchitectureMapping,
            deviceModel || NOT_FOUND
          );
          url = updateAppUrls(
            deviceArchitecture,
            locale!,
            appVersions!,
            pageName!
          );
          setClickApkUrl(url);
          anchor.href = url;
          debounce(() => anchor.click(), 120)();
        }
      } else {
        debounce(() => anchor.click(), 120)();
      }
    }

    if (!getOnlineStatus()) {
      return;
    }

    const btnType: string | null = isiOSDevice ? IOS : null;

    debounce(() => {
      const element = document.getElementById("id_howToInstall");
      element?.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
      onScrollStop((listener) => {
        element?.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
        window.removeEventListener("scroll", listener);
      });
    }, 120)();

    if (isAutoDownloadFlow && type === BANNER_DOWNLOAD_BUTTON_TYPE) {
      Analytics.getInstance().logEvent(
        { action: "App_Download_Event" },
        {
          deviceArchitecture,
          deviceModelNumber,
        }
      );
    } else if (!isAutoDownloadFlow) {
      Analytics.getInstance().logEvent(
        { action: "App_Download_Event" },
        {
          deviceArchitecture,
          deviceModelNumber,
        }
      );

      // sendAnalytics(type, btnType);

      if (isiOSDevice && iosCtaData?.variant) {
        Analytics.getInstance().logEvent(
          iosCtaColorClickEvent(iosCtaData?.variant)
        );
      }
    }
  };

  useEffect(() => {
    if (isIos && iosCtaData?.variant) {
      Analytics.getInstance().logEvent(iosCtaColorEvent(iosCtaData?.variant));
    }
  }, [isIos]);
  return fixedDownloadButtonData ? (
    <>
      {isiOSDevice ? (
        <a
          id={`${type}_ios_downloadButton`}
          className={styles.iosButtonContainer}
          href={apkUrl}
          data-btn-type={IOS}
          onClick={(event) => {
            handleDownloadClickWithState();
          }}
          suppressHydrationWarning
        >
          <img
            className={styles.iosButtonImage_FB}
            src={
              type === HEADER_DOWNLOAD_BUTTON_TYPE
                ? fixedDownloadButtonData.iosHeaderImg ||
                  "https://d3g4wmezrjkwkg.cloudfront.net/website/images/new/Download_on_the_App_Store_Badge_US-UK_RGB_wht.svg"
                : iosCtaData &&
                  iosCtaData.variant === VariantB &&
                  type === BANNER_DOWNLOAD_BUTTON_TYPE
                ? fixedDownloadButtonData.iosBannerImg ||
                  "https://d3g4wmezrjkwkg.cloudfront.net/website/images/white_extra_width.svg"
                : fixedDownloadButtonData.iosFixedImg ||
                  "https://d3g4wmezrjkwkg.cloudfront.net/website/images/new/download-on-the-app-store-badge-us-uk-rgb-blk.svg"
            }
            alt="winzo gold logo"
            style={type === HEADER_DOWNLOAD_BUTTON_TYPE ? { height: 30 } : {}}
          />
        </a>
      ) : (
        <>
          <span
            style={{
              display: `${
                type === HEADER_DOWNLOAD_BUTTON_TYPE ? "none" : "block"
              }`,
              color: `${type === FIXED_DOWNLOAD_BUTTON_TYPE ? "#000" : "#fff"}`,
              textAlign: "center",
              padding: 0,
              fontSize: "12px",
              fontWeight: 500,
              marginBottom: "5px",
            }}
          >
            {parse(downloadButtonAboveText)}
          </span>

          <ButtonClickAnimation
            disabled={fixedDownloadButtonData.disabled}
            disableBreathing={fixedDownloadButtonData.disabled}
          >
            <span
              onClick={(event) => {
                handleDownloadClickWithState();
              }}
            >
              <span
                id={`${type}_downloadButton`}
                className={styles.buttonContainer}
              >
                <div style={{ position: "relative" }}>
                  {showAnimation &&
                    downloadButtonState === DownloadButtonState.Initial && (
                      <>
                        <div
                          className={multipleClassName(
                            styles.animContainer,
                            styles.container1
                          )}
                        />
                        <div
                          className={multipleClassName(
                            styles.animContainer,
                            styles.container2
                          )}
                        />
                        <div
                          className={multipleClassName(
                            styles.animContainer,
                            styles.container3
                          )}
                        />
                      </>
                    )}
                  <div
                    className={multipleClassName(
                      styles.mainContainer,
                      "buttonContainerGlobal",
                      storyFlow ? styles.storyMainContainer : "",
                      showAnimation &&
                        downloadButtonState === DownloadButtonState.Initial
                        ? styles.animationContainer
                        : ""
                    )}
                    style={
                      downloadButtonState === DownloadButtonState.DownloadAgain
                        ? {
                            background: storyFlow
                              ? "linear-gradient(to right, #e18700 0%, #fdd083 50%, #e18700 100%)"
                              : "linear-gradient(to bottom, #F8E12C, #F8E12C)",
                          }
                        : {
                            background:
                              "linear-gradient(to bottom, #b1fd2b, #31eb6f)",
                          }
                    }
                  >
                    <div
                      className={multipleClassName(
                        styles.content,
                        "buttonContentGlobal"
                      )}
                      style={{
                        padding: `${
                          type !== HEADER_DOWNLOAD_BUTTON_TYPE
                            ? "8px 10px"
                            : "6px"
                        }`,
                        justifyContent: `${
                          type !== HEADER_DOWNLOAD_BUTTON_TYPE
                            ? "space-between"
                            : "space-around"
                        }`,
                        background: `${
                          downloadButtonState ===
                          DownloadButtonState.DownloadAgain
                            ? storyFlow
                              ? "linear-gradient(to bottom, #ffbc3b, #d06b0d), linear-gradient(to right, #e18700 4%, rgba(255, 224, 187, 0.6) 51%, #d47300 100%)"
                              : "linear-gradient(to bottom, #FD9F13, #AC6803)"
                            : "linear-gradient(to bottom, #57cc03, #004f1c)"
                        }`,
                        fontSize: `${
                          storyFlow
                            ? "clamp(15px, 4.4vw, 85px)"
                            : "clamp(14px, 4.4vw, 85px)"
                        }`,
                      }}
                    >
                      <div
                        className={
                          downloadButtonState ===
                          DownloadButtonState.Downloading
                            ? styles.shimmer2
                            : styles.shimmer
                        }
                      ></div>
                      {type !== HEADER_DOWNLOAD_BUTTON_TYPE && (
                        <div
                          className={multipleClassName(
                            "buttonGlobalIcon",
                            styles.iconView
                          )}
                        >
                          <CustomImageComponent
                            layout={"fill"}
                            src="https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/android-img.png"
                            alt="winzo gold logo"
                            priority={true}
                          />
                        </div>
                      )}

                      <p
                        className={
                          storyFlow && !isiOSDevice
                            ? styles.downloadButtonTextStory
                            : ""
                        }
                        style={
                          type === HEADER_DOWNLOAD_BUTTON_TYPE
                            ? {
                                fontSize: isGetAppFacebook
                                  ? "13px"
                                  : dynamicCssSize(40, 8),
                                fontStyle: "italic",
                                fontWeight: 600,
                                animation: `${
                                  downloadButtonState ===
                                  DownloadButtonState.Downloading
                                    ? `${styles.ellipsisAnimation} steps(1, end) 1s infinite`
                                    : countdown &&
                                      countdown > 0 &&
                                      downloadButtonState ===
                                        DownloadButtonState.DownloadClicked
                                    ? `${styles.scale} 1s infinite`
                                    : "none"
                                }`,
                              }
                            : {
                                animation: `${
                                  downloadButtonState ===
                                  DownloadButtonState.Downloading
                                    ? `${styles.ellipsisAnimation} steps(1, end) 1s infinite`
                                    : countdown &&
                                      countdown > 0 &&
                                      downloadButtonState ===
                                        DownloadButtonState.DownloadClicked
                                    ? `${styles.scale} 1s infinite`
                                    : "none"
                                }`,
                              }
                        }
                      >
                        {downloadButtonText}
                      </p>
                      {type !== HEADER_DOWNLOAD_BUTTON_TYPE && (
                        <div
                          className={multipleClassName(
                            styles.arrow3container,
                            styles.iconView,
                            "buttonGlobalIcon"
                          )}
                        >
                          {downloadButtonState ===
                          DownloadButtonState.DownloadAgain ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24px"
                              className={styles.svg}
                              height="22px"
                              viewBox="0 0 24 22"
                              fill={"#ffffff"}
                              version="1.1"
                            >
                              <path d="M 11.566406 0.09375 C 17.589844 -0.6875 23.125 3.574219 23.90625 9.585938 C 24.285156 12.5 23.503906 15.386719 21.707031 17.714844 C 19.910156 20.042969 17.3125 21.53125 14.394531 21.910156 C 13.929688 21.96875 13.460938 22 12.996094 22 C 8.964844 22 5.160156 19.765625 3.242188 16.152344 L 4.328125 15.578125 C 6.226562 19.160156 10.210938 21.214844 14.238281 20.695312 C 16.832031 20.359375 19.136719 19.035156 20.734375 16.96875 C 22.332031 14.898438 23.023438 12.335938 22.6875 9.746094 C 21.996094 4.402344 17.074219 0.617188 11.722656 1.308594 C 7.667969 1.832031 4.425781 4.753906 3.472656 8.667969 L 5.96875 7.386719 L 6.53125 8.476562 L 2.175781 10.714844 L 0 6.492188 L 1.09375 5.929688 L 2.304688 8.28125 C 2.804688 6.332031 3.851562 4.515625 5.308594 3.105469 C 7.023438 1.441406 9.1875 0.398438 11.566406 0.09375 Z M 11.566406 0.09375 " />
                            </svg>
                          ) : (
                            <>
                              <svg
                                className={styles.arrow3}
                                fill={"#ffffff"}
                                style={{
                                  animation: `${
                                    downloadButtonState ===
                                    DownloadButtonState.Downloading
                                      ? `${styles.a3} .5s infinite alternate`
                                      : "none"
                                  }`,
                                }}
                                clipRule="evenodd"
                                fillRule="evenodd"
                                strokeLinejoin="round"
                                strokeMiterlimit="2"
                                viewBox="0 0 24 22"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m9.001 13.022h-3.251c-.412 0-.75.335-.75.752 0 .188.071.375.206.518 1.685 1.775 4.692 4.945 6.069 6.396.189.2.452.312.725.312.274 0 .536-.112.725-.312 1.377-1.451 4.385-4.621 6.068-6.396.136-.143.207-.33.207-.518 0-.417-.337-.752-.75-.752h-3.251v-9.02c0-.531-.47-1.002-1-1.002h-3.998c-.53 0-1 .471-1 1.002z"
                                  fillRule="nonzero"
                                />
                              </svg>
                              <span></span>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </span>
            </span>
          </ButtonClickAnimation>
          <div style={{ display: "none" }} id="buttonDownloadLink">
            <a href={apkUrl} ref={anchorRef} suppressHydrationWarning></a>
          </div>
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default FixedDownloadButton;
