import React, { useState } from "react";
import styles from "./HeaderVersionToggle.module.css";
import { ISeasonToggleProps } from "../../../lib/types/components";
import CustomLinkComponent from "../../common/CusotmLink/CustomLinkComponent";

const HeaderVersionToggle = (props: ISeasonToggleProps) => {
    const [showToggle, setShowToggle] = useState<boolean>(false);
    return (
        <>
            <style jsx>{`
        .arrowDown {
            transform: ${showToggle ? "rotate(180deg)" : "rotate(0deg)"};
            transition: all ease 0.2s;
        }
      `}</style>
            <div className={styles.seasonToggleParentContainer}>
                <div
                    className={styles.toggleButtonContainer}
                    onClick={() => {
                        setShowToggle((prev) => !prev);
                    }}
                >
                    <p className={styles.toggleButtonText}>{props.currentSeason}</p>
                    <span className={"arrowDown"}>
                        <svg
                            width="12"
                            height="7"
                            viewBox="0 0 14 8"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 1.09 1.12 0 7 5.804 12.88 0 14 1.09 7 8z"
                                fill="rgba(255,255,255,0.4)"
                                fillRule="nonzero"
                            />
                        </svg>
                    </span>
                </div>
                {showToggle && 
                    <div className={styles.toggleContainer}>{
                        props.seasonData.map((item, idx)=>{
                            return (
                                <CustomLinkComponent href={item.url} className={styles.seasonItem} key={"seasonItem_" + idx}>
                                    {item.text}</CustomLinkComponent>
                            )
                        })
                    }</div>
                }
            </div>
        </>
    );
};

export default HeaderVersionToggle;
