"use client"
import React from "react";
import styles from "./ButtonClickAnimation.module.css"

const ButtonClickAnimation = ({children, disabled = false, disableBreathing = true, ...rest}) => {
    if (disabled) return children

    return (
        <>
            <style jsx>{`
              .div {
                animation: ${disableBreathing ? "none" : "breathing 3s ease-out infinite alternate-reverse"}
              }
            `}</style>
            <div {...rest}
                 className={styles.div}>
                {children}
            </div>
        </>
    )
}

export default (ButtonClickAnimation)